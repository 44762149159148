import { Row, Col, Image, Button } from 'antd'
import { Trans, withTranslation } from 'react-i18next';

const Policy = () => {
    return (
        <div style={{ margin: '10px, 0', backgroundColor: '#fff', color: 'black', fontSize: '16px' }}>
            <div class="policy" style={{paddingTop: '80px'}}>
                <h2 class="textline"><Trans i18nKey='policy.policy' /></h2>

                <p class="liner"><Trans i18nKey='policy.introduce' /></p>
                <p class="descrip"><Trans i18nKey='policy.introduce_1' /></p>
                <p class="descrip"><Trans i18nKey='policy.introduce_2' /></p>
                <p class="descrip"><Trans i18nKey='policy.introduce_3' /></p>
                <p class="descrip"><Trans i18nKey='policy.introduce_4' /></p>
                <p class="descrip"><Trans i18nKey='policy.introduce_5' /></p>

                <p class="liner"><Trans i18nKey='policy.when' /></p>

                <p class="descrip"><Trans i18nKey='policy.when_1' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1a' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1b' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1c' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1d' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1e' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1f' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1g' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1h' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1i' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.when_1j' /></p>
                <p class="descrip"><Trans i18nKey='policy.when_2' /></p>


                <p class="liner"><Trans i18nKey='policy.what' /></p>

                <p class="descrip"><Trans i18nKey='policy.what_1' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1a' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1b' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1c' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1d' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1e' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1f' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1g' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1h' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1i' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1j' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1k' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1l' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.what_1m' /></p>
                <p class="descrip"><Trans i18nKey='policy.what_2' /></p>
                <p class="descrip"><Trans i18nKey='policy.what_3' /></p>
                <p class="descrip"><Trans i18nKey='policy.what_4' /></p>

                <p class="liner"><Trans i18nKey='policy.other' /></p>

                <p class="descrip"><Trans i18nKey='policy.other_1' /></p>
                <p class="descrip"><Trans i18nKey='policy.other_2' /></p>
                <p class="descrip"><Trans i18nKey='policy.other_3' /></p>


                <p class="liner"><Trans i18nKey='policy.cookies' /></p>
                <p class="descrip"><Trans i18nKey='policy.cookies_1' /></p>
                <p class="descrip"><Trans i18nKey='policy.cookies_2' /></p>

                <p class="liner"><Trans i18nKey='policy.how' /></p>
                <p class="descrip"><Trans i18nKey='policy.how_1' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1a' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1b' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1c' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1d' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1e' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1f' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1g' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1h' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1i' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1j' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1k' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1l' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1m' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1n' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1o' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1p' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1q' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1r' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1s' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1t' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.how_1u' /></p>
                <p class="descrip"><Trans i18nKey='policy.how_2' /></p>

                <p class="liner"><Trans i18nKey='policy.save' /></p>

                <p class="descrip"><Trans i18nKey='policy.save_1' /></p>
                <p class="descrip"><Trans i18nKey='policy.save_2' /></p>
                
                <p class="liner"><Trans i18nKey='policy.disclose' /></p>

                <p class="descrip"><Trans i18nKey='policy.disclose_1' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.disclose_1a' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.disclose_1b' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.disclose_1c' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.disclose_1d' /></p>
                <p class="descrip"><Trans i18nKey='policy.disclose_2' /></p>
                <p class="descrip"><Trans i18nKey='policy.disclose_3' /></p>
                <p class="descrip"><Trans i18nKey='policy.disclose_4' /></p>

                <p class="liner"><Trans i18nKey='policy.kid' /></p>

                <p class="descrip"><Trans i18nKey='policy.kid_d' /></p>


                <p class="liner"><Trans i18nKey='policy.3rd' /></p>

                <p class="descrip"><Trans i18nKey='policy.3rd_1' /></p>
                <p class="descrip"><Trans i18nKey='policy.3rd_2' /></p>
                <p class="descrip"><Trans i18nKey='policy.3rd_3' /></p>

                <p class="liner"><Trans i18nKey='policy.security' /></p>

                <p class="descrip"><Trans i18nKey='policy.security_1' /></p>
                <p class="descrip"><Trans i18nKey='policy.security_2' /></p>
                <p class="descrip"><Trans i18nKey='policy.security_3' /></p>


                <p class="liner"><Trans i18nKey='policy.request' /></p>

                <p class="descrip"><Trans i18nKey='policy.request_1' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.request_1a' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.request_1b' /></p>
                <p class="descrip"><Trans i18nKey='policy.request_2' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.request_2a' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.request_2b' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.request_2c' /></p>

                <p class="liner"><Trans i18nKey='policy.euuser' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_d' /></p>


                <p class="desone"><Trans i18nKey='policy.euuser_1' /></p>

                <p class="descrip intext"><Trans i18nKey='policy.euuser_11' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_12' /></p>

                <p class="desone"><Trans i18nKey='policy.euuser_2' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2a' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2a1' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2a2' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2a3' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2a4' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2a5' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2b' /></p>
                <p class="descrip intext"><Trans i18nKey='policy.euuser_2c' /></p>

                <p class="desone"><Trans i18nKey='policy.euuser_3' /></p>

                <p class="descrip"><Trans i18nKey='policy.euuser_3a' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_4' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_4a' /></p>

                <p class="desone"><Trans i18nKey='policy.euuser_5' /></p>

                <p class="descrip"><Trans i18nKey='policy.euuser_55' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_5a' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_5b' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_5c' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_5d' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_6' /></p>
                <p class="descrip"><Trans i18nKey='policy.euuser_66' /></p>

                <p class="desone"><Trans i18nKey='policy.euuser_7' /></p>

                <p class="descrip"><Trans i18nKey='policy.euuser_77' /></p>


                <p class="liner"><Trans i18nKey='policy.where' /></p>
                <p class="descrip"><Trans i18nKey='policy.where_1' /></p>
                <p class="descrip"><Trans i18nKey='policy.where_2' /></p>
                <p class="descrip"><Trans i18nKey='policy.where_3' /></p>

                <p class="liner"><Trans i18nKey='policy.support' /></p>

                <p class="descrip"><Trans i18nKey='policy.support_' /></p>
            </div>
        </div>
    )
};

export default withTranslation()(Policy);
