import { Outlet } from "react-router-dom";
import MyHeader from './Header'
import Footer from './Footer'
import DocumentTitle from "react-document-title"
import React, { Component } from 'react'
import { Breadcrumb, Divider, Layout, Menu, MenuProps, theme, Col, Row, Button, Dropdown, Space, Typography } from 'antd';
import { Trans, withTranslation } from 'react-i18next';
import { LocalStore } from '../utils/LocalStore/index';
import i18n from '../utils/locales/i18n';

const { Header, Content } = Layout;

class Layout2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language : 'en'
    }
  }
  componentDidMount() {
    let lg = LocalStore.getInstance().read('language') || "en";
    this.setLanguage(lg);
  }

  setLanguage(language) {
    console.log(`Setting language ${language}`);
    this.setState({ language: language });
    LocalStore.getInstance().save('language', language);
    i18n.changeLanguage(language);
  }
  render() {
    const { title } = this.props;
    let items = [];
    return (
      <DocumentTitle title={`${title ? title + ' | ' : ''} Nota Studio`}>
        <div>
          <Header className='nota-header'>
            <Row align="middle">
              <Col span={6} align="left" style={{ padding: '5px 0' }}>
                <a className="logo" href='/#/'>
                  <img className="img-fluid nota-header-img" src="logo192.png" alt="" />
                </a>
              </Col>
              <Col span={18} align="right" className='nota-p-header-menu'>
                
                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: [this.state.language],
                  }}
                  dropdownRender={(menu) => (
                    <div style={{ border: '1px solid black', backgroundColor: 'white' }}>
                      <Button type="none" onClick={() => this.setLanguage('vi')}>
                        {/* <img src='images/vi-flag.png' width='20px' height='' alt='' /> */}
                        Tiếng Việt
                      </Button>
                      <br></br>
                      <Button type="none" onClick={() => this.setLanguage('en')}>
                        {/* <img src='images/en-flag.png' width='20px' height='' alt='' /> */}
                        English
                      </Button>
                    </div>
                  )}
                >
                  <Typography.Link>
                    {this.state.language == 'vi' &&
                      <Space style={{ paddingLeft: 16, fontSize: '14px', fontWeight: 'bold' }}>
                        <img src='images/vi-flag.png' width='20px' height='' alt='' />VI
                      </Space>}
                    {this.state.language == 'en' &&
                      <Space style={{ paddingLeft: 16, fontSize: '14px', fontWeight: 'bold' }}>
                        <img src='images/en-flag.png' width='20px' height='' alt='' />EN
                      </Space>
                    }
                  </Typography.Link>
                </Dropdown>
              </Col>
            </Row>
          </Header >
          <div>
            <div className="primary">
              <Outlet />
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default withTranslation()(Layout2);
