import ReactDOM from "react-dom/client";
import React from 'react';
import { BrowserRouter, Routes, Route, HashRouter as Router } from "react-router-dom";
import Layout from "./pages/Layout";
import Layout2 from "./pages/Layout2";
import Home from "./pages/Home";
import Policy from "./pages/Policy";
import 'antd/dist/reset.css';
import './App.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import i18n from './utils/locales/i18n';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
});


export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/policy" element={<Layout2 />}>
          <Route index element={<Policy />} />
        </Route>
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
);

// ReactDOM.render(
//   <React.StrictMode>
//     <I18nextProvider i18n={i18n}>
//       <App />
//     </I18nextProvider>

//   </React.StrictMode>,
//   document.getElementById('root')
// );